import React, { createContext, useContext, useState } from 'react';

// Create the context without TypeScript types
const ChatContext = createContext(undefined);

export const ChatProvider = ({ children }) => {
	const [chatMessages, setChatMessages] = useState([]);
	const [availableThreads, setAvailableThreads] = useState([]);
	const [activeThread, setActiveThread] = useState(null);
	const [userMessage, setUserMessage] = useState('');
	const [threadSelectorOpen, setThreadSelectorOpen] = useState(false);

	const setChatHistoryInLocalStorage = (chatHistory, threadId) => {
		localStorage.setItem(`chatHistory_${threadId}`, JSON.stringify(chatHistory));
	};
	const getChatHistoryFromLocalStorage = (threadId) => {
		const chatHistory = JSON.parse(localStorage.getItem(`chatHistory_${threadId}`));
		if (chatHistory) {
			return chatHistory;
		}
		return false;
	};

	return (
		<ChatContext.Provider
			value={{
				chatMessages,
				setChatMessages,
				availableThreads,
				setAvailableThreads,
				activeThread,
				setActiveThread,
				userMessage,
				setUserMessage,
				setChatHistoryInLocalStorage,
				getChatHistoryFromLocalStorage,
				threadSelectorOpen,
				setThreadSelectorOpen,
			}}>
			{children}
		</ChatContext.Provider>
	);
};

export const useChat = () => {
	const context = useContext(ChatContext);
	if (context === undefined) {
		throw new Error('useChat must be used within a ChatProvider');
	}
	return context;
};
