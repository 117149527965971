import { useEffect, useState } from 'react';
import { useJobs } from '../../context/JobsContext';
import { JobCard } from './JobCard';
import { useApi } from '../../utils/api';
import { CreateJobInput } from './CreateJobInput';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

export const Jobs = () => {
	const { jobs, setJobs } = useJobs();
	const api = useApi();
	const navigate = useNavigate();
	const [isDoingFirstLoad, setIsDoingFirstLoad] = useState(true);

	useEffect(() => {
		// This function runs only once when the component is mounted
		setIsDoingFirstLoad(true);
		getAllJobs();

		/* Run getAllJobs every 10 seconds */
		const interval = setInterval(() => {
			getAllJobs();
		}, 10000);

		// Optional cleanup function, if needed
		return () => {
			// This runs when the component is unmounted
			window.clearInterval(interval);
		};
	}, []); // Empty dependency array ensures it only runs on mount

	const handleSetJobs = (newJobs) => {
		setJobs((oldJobs) => {
			const newJobs = [jobs];
			return newJobs;
		});
		setJobs(newJobs);
	};

	const getAllJobs = async () => {
		try {
			const response = await api.get(`/jobs/getAllJobs/`);
			let jobs = response.data;
			handleSetJobs(jobs);
		} catch (error) {
			if (error && error.response && error.response.status === 401) {
				navigate('/signin');
			}
		} finally {
			setIsDoingFirstLoad(false);
		}
	};

	const renderJobCards = () => {
		if (jobs.length) {
			return jobs.map((job, index) => {
				return <JobCard job={job} key={`job-id-${job.id}`} jobUpdated={getAllJobs} />;
			});
		}
	};

	const showLoadingSkeletons = () => {
		return (
			<div>
				<div className="mb-3">
					<Skeleton count={4} />
				</div>
				<div className="mb-3">
					<Skeleton count={4} />
				</div>
				<div className="mb-3">
					<Skeleton count={4} />
				</div>
			</div>
		);
	};

	return (
		<div>
			<div>
				<div className="mb-2">
					<div className="col-12 col-md-12 col-lg-8 offset-lg-2">
						<CreateJobInput getAllJobs={getAllJobs} />
					</div>
				</div>
			</div>
			<div className="">
				<div className="">
					<div className="">
						{isDoingFirstLoad && !jobs.length && showLoadingSkeletons()}
						{renderJobCards()}
					</div>
				</div>
			</div>
		</div>
	);
};
