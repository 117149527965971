import React, { useEffect, useState } from 'react';
import { useChat } from '../../context/ChatContext';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApi } from '../../utils/api';

const InvitationCodes = () => {
	const { alert, handleSetAlert } = useAlert();
	const api = useApi();
	const [invitationCodes, setInvitationCodes] = useState([]);

	const {} = useChat();

	useEffect(() => {
		// Define an async function to fetch data
		const fetchData = async () => {
			try {
				const response = await api.get(`/admin/invitationCodes`);
				setInvitationCodes(response.data);
			} catch (error) {
				error && error.response && error.response.data && handleSetAlert({ ...error.response.data, timeout: 5000 });
			} finally {
			}
		};

		fetchData(); // Call the async function
	}, []); // Empty dependency array ensures this runs only once when the component mounts

	const formatDate = (date) => {
		const formattedDate = new Date(date);
		return formattedDate.toLocaleDateString('sv-SE');
	};

	return (
		<div className="invitation-codes">
			<table className="table table-striped">
				<thead>
					<tr>
						<th scope="col">Kod</th>
						<th scope="col">Använd</th>
						<th scope="col">Användare</th>
					</tr>
				</thead>
				<tbody>
					{invitationCodes.map((code, index) => {
						return (
							<tr key={`invitation-code-${code.id}`}>
								<td>{code.code}</td>
								<td>{code.is_used ? formatDate(code.updated_at) : '–'}</td>
								<td>{code.user ? code.user.email : '–'}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export { InvitationCodes };
