import { useChat } from '../../context/ChatContext';
import { useState, useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import ChatPost from './ChatPost';

import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';

import ThreadSelector from './ThreadSelector';
import ChatMessageSuggestions from './ChatMessageSuggestions';
import { ChatMessageInput } from './ChatMessageInput';
import Skeleton from 'react-loading-skeleton';
import { useApi } from '../../utils/api';
import Markdown from 'react-markdown';

export const Chat = () => {
	const {
		chatMessages,
		setChatMessages,
		activeThread,
		userMessage,
		setUserMessage,
		setChatHistoryInLocalStorage,
		getChatHistoryFromLocalStorage,
		threadSelectorOpen,
		setThreadSelectorOpen,
	} = useChat();
	const [isLoading, setIsLoading] = useState(false);
	const chatMessagesRef = useRef(null);
	const { alert, handleSetAlert } = useAlert();
	const api = useApi();

	const addChatHistory = (messageObject) => {
		setChatMessages((history) => {
			/* If the message is from the user, scroll to the bottom of the chat */
			if (messageObject.role === 'user') {
				window.setTimeout(scrollChatToBottom, 100);
			}
			//window.setTimeout(scrollChatToBottom, 100); // This is a hack to make sure the chat scrolls to the bottom after the state has been updated
			const newHistory = [...history, messageObject]; // I had to use spread operator here to force a state update and re-render
			setChatHistoryInLocalStorage(newHistory, activeThread.fb_id);
			return newHistory;
		});
	};

	const chatSuggestionSelected = (suggestion) => {
		setUserMessage(suggestion);
	};

	/* Render chat messages correctly based on what type of chat message it is */
	const renderChatMessages = () => {
		return chatMessages.map((message, index) => {
			if (message.role === 'user') {
				return <ChatMessage key={`message-${index}`} message={message} />;
			} else if (message.role === 'model') {
				let subMessages = message.parts[0].text;
				if (!Array.isArray(subMessages)) {
					// Check if submessages is an array. This is done to catch issues where the API doesn't return JSON as expected
					return <ChatMessage key={`model-message-${index}`} message={message} />;
				} else {
					return subMessages.map((subMessage, subMessageIndex) => {
						if (subMessage.messageType === 'message') {
							subMessage.role = 'model';
							return <ChatMessage key={`model-message-${subMessageIndex}`} message={subMessage} />;
						} else if (subMessage.messageType === 'post') {
							return <ChatPost key={`model-post-${subMessageIndex}`} message={subMessage} />;
						}
					});
				}
			}
		});
	};

	/* Format the chat history to send to the API. The API expects the chat history to be in a specific format. */
	const formatChatHistoryToSend = (chatHistory) => {
		let formattedChatHistory = [];
		chatHistory.map((message) => {
			if (message.role === 'user') {
				formattedChatHistory.push({ role: 'user', parts: [{ text: message.parts[0].text }] });
			}
			if (message.role === 'model') {
				formattedChatHistory.push({ role: 'model', parts: [{ text: JSON.stringify(message.parts[0].text) }] });
			}
		});
		return formattedChatHistory;
	};

	/* Handle sending of the chat message and the response from the API */
	const handleSubmit = async (event) => {
		if (event) {
			event.preventDefault();
		}
		if (isLoading) {
			return;
		}
		setIsLoading(true);

		/* Set up the chat history before sending to the API */
		let chatHistory = [...chatMessages];
		chatHistory.push({ role: 'user', parts: [{ text: userMessage }] });
		addChatHistory({ role: 'user', parts: [{ text: userMessage }] });
		setUserMessage('');

		try {
			const response = await api.post(`/chat/thread/${activeThread.fb_id}/`, {
				history: formatChatHistoryToSend(chatHistory),
			});
			addChatHistory({ role: 'model', parts: [{ text: response.data }] });
		} catch (error) {
			error &&
				error.response &&
				error.response.data &&
				error.response.data.message &&
				handleSetAlert({ message: error.response.data.message, type: 'danger', timeout: 10000 });
		} finally {
			setIsLoading(false);
		}
	};

	const scrollChatToBottom = () => {
		if (chatMessagesRef.current) {
			chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
		}
	};

	const getGreeting = () => {
		let greeting = { __html: 'Hej! Du kan ställa frågor till mig om Flashback-tråden så försöker jag svara så gott jag kan.' };
		if (activeThread) {
			greeting.__html = `Hej! Du kan ställa frågor till mig om Flashback-tråden "<a href="https://www.flashback.org/t${activeThread.fb_id}" target="_blank">${activeThread.title}</a>" så försöker jag svara så gott jag kan. `;
			if (activeThread.number_of_posts > 0) {
				greeting.__html += `Jag har läst ${activeThread.number_of_posts} inlägg i tråden.`;
			}
			if (activeThread.short_summary) {
				greeting.__html += `<br><br>${activeThread.short_summary}`;
			}
		}
		return (
			<div>
				<div className="chat-message-wrapper model">
					<div className="chat-message model">
						<span dangerouslySetInnerHTML={greeting}></span>
					</div>
				</div>
			</div>
		);
	};

	/* Load chat history from local storage when the active thread changes */
	useEffect(() => {
		if (!activeThread) return; // Check if activeThread exists
		const history = getChatHistoryFromLocalStorage(activeThread.fb_id);
		if (history) {
			setChatMessages(history);
			window.setTimeout(() => {
				scrollChatToBottom();
			}, 100);
		}
	}, [activeThread]);

	return (
		<div className="outer-chat-container">
			<div className="inner-chat-container">
				<ThreadSelector />
				<div className="chat-wrapper">
					{threadSelectorOpen && <div className="thread-selector-overlay" onClick={() => setThreadSelectorOpen(false)} />}
					<div className="chat-container" ref={chatMessagesRef}>
						{getGreeting()}
						<ChatMessageSuggestions
							suggestions={activeThread && activeThread.suggested_questions ? activeThread.suggested_questions : false}
							handleSelectSuggestion={chatSuggestionSelected}
						/>
						{renderChatMessages()}
						{isLoading && (
							<div className="chat-message-wrapper model">
								<div className="chat-message chat-message-skeleton model">
									<Skeleton count={3} />
								</div>
							</div>
						)}
						{alert && <Alert message={alert.message} type={alert.type} timeout={10000} />}
					</div>
				</div>
				<ChatMessageInput handleSubmit={handleSubmit} />
			</div>
		</div>
	);
};
